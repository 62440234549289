import React from 'react';
import Layout from '@layouts';
import { Link } from 'gatsby';
import styled from 'styled-components';

const ReaderLayout = ({ children, pageContext }) => {
	const { title, description } = pageContext.frontmatter;

	return (
		<Layout title={title} description={description}>
			<StyledContent>{children}</StyledContent>
			<Link to="/">&larr; Back to home</Link>
		</Layout>
	);
};

export default ReaderLayout;

const StyledContent = styled.section`
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1.5em;
		font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
			'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
			'Helvetica Neue', sans-serif;
		font-weight: 900;
	}
	p {
		font-size: 22px;
		font-family: 'PT Serif', Georgia, 'Times New Roman', Times, serif;
	}
`;
